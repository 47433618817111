.datatable{
    height: 600px;
    padding: 20px;

    .datatableTitle{
        widows: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link{
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .cellWithImg{
        display: flex;
        align-items: center;


        .cellImg{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .cellWWithStatus{
        padding: 5px;
        border-radius: 5px;

        &.active{
            color: green;
            background-color: rgba(0,128,0,0.151);
        }

        &.pending{
            color: goldenrod;
            background-color: rgba(189,189,3,0.103);        
        }
        &.passive{
            color: crimson;
            background-color: rgba(189,189,3,0.103);        
        }
    }
    .cellAction{
        display: flex;
        align-items: center;
        gap: 15px;

        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px dotted rgba(0, 0, 139, 0.59);
        }
        .deleteButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px solid rgba(220,20, 60, 0.6);
            cursor: pointer;

        }
    }
}