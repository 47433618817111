.sidebar{
    
    flex: 1;
    border-right: 0.5px solid rgb(230, 230, 230);
    min-height: 100vh;
    background-color: white;
    .about{
        font-size: 8px;
    }
    .top{
        height:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        .logo{
            font-size: 20px;
            font-weight: bold;
            color: purple;
        }
    }
    hr{
        height: 0;
        border: 0.5px solid rgb(230, 230, 230);
    }
    .center{
        padding-left: 10;

        .ul{
            list-style: none;
            margin: 0;
            padding: 1em;
            .title{
                font-size: 10px;
                font-weight: bold;
                color: grey;
                margin-top: 15px;
                margin-bottom: 5px;
            }
            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover{
                    background-color: #fcd430;
                }
                .icon{
                    font-size: 18px;
                    color: purple;
                }
                span{
                    font-size: 13px;
                    font-weight: 700;
                    color: grey;
                    margin-left: 10px;
                }
            }
        }
    }
    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;
            
            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color:black;
            }
            &:nth-child(3){
                background-color: darkblue;
            }
            &:nth-child(4){
                background-color: black;
            }
        }
    }
}